import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassChart } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassChart'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import DesignAdviceIcon from '../../public/assets/images/design_advice.svg'
import OptimizationIcon from '../../public/assets/images/optimization.svg'
import Image from 'next/image'
import dynamic from 'next/dynamic'
const YouTubePopup = dynamic(() => import('components/youtube_popup'))

export default function SimulationTypes() {
  return (
    <>
      <section className="why py-5 position-relative" id="why">
        <div className="container">
          <h2 className="title pb-lg-4">Advanced Optimization Solutions</h2>
          <div className="row justify-content-center pt-5 pb-4">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="card h-100 has-icon">
                <div className="card-body position-relative">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassChart}
                    size="5x"
                    style={{ top: 35, right: 10, opacity: 0.1, zIndex: -1 }}
                    className="position-absolute text-primary"
                  />
                  <h3 className="card-title pb-3">Analysis</h3>
                  <p>
                    Conduct detailed aerodynamic and hydrodynamic simulations to
                    understand flow patterns, forces, and other critical factors
                    impacting your design.{' '}
                    <YouTubePopup videoId="hrvj5hJIZn8">
                      <FontAwesomeIcon icon={faPlay} /> Learn More
                    </YouTubePopup>{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="card has-icon h-100">
                <div className="card-body">
                  <Image
                    src={DesignAdviceIcon}
                    width={100}
                    height={100}
                    className="position-absolute"
                    style={{
                      top: 35,
                      right: 10,
                      opacity: 0.1,
                      zIndex: -1,
                      fill: '#00bbf0',
                    }}
                    alt="Design Advice"
                  />
                  <h3 className="card-title pb-3">Design Advice</h3>
                  <p>
                    Let AirShaper provide automated design improvement
                    suggestions using intuitive color-coded visualizations
                    across your design's surface.{' '}
                    <YouTubePopup videoId="Ac469ecXQHQ">
                      <FontAwesomeIcon icon={faPlay} /> Learn More
                    </YouTubePopup>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="card has-icon h-100">
                <div className="card-body">
                  <Image
                    src={OptimizationIcon}
                    width={80}
                    height={80}
                    className="position-absolute"
                    style={{
                      top: 35,
                      right: 10,
                      opacity: 0.1,
                      zIndex: -1,
                      fill: '#00bbf0',
                    }}
                    alt="Optimization"
                  />
                  <h3 className="card-title pb-3">Morphing</h3>
                  <p>
                    Automatically morph your design's 3D shape within a defined
                    design space, allowing for optimized form and function.{' '}
                    <YouTubePopup videoId="ok4HOL9JRIk">
                      <FontAwesomeIcon icon={faPlay} /> Learn More
                    </YouTubePopup>
                  </p>
                </div>
              </div>
            </div>
            <small className="text-center pt-3 fst-italic">
              Note: Morphing and Design Advice are available only for external
              aerodynamics (excludes rotating elements such as propellers).
            </small>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <a
              className="btn btn-primary btn-lg"
              href="https://app.airshaper.com/plans"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started
            </a>
          </div>
        </div>
      </section>
    </>
  )
}
